import loaderImg from "@assets/loader.png";
import { clsx } from "clsx";

export function Spin({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        className,
        "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none",
      )}
    >
      <img className="animate-spin" src={loaderImg} alt="loader" width="100%" height="100%" />
    </div>
  );
}
